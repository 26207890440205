import { RunnerAppConfigurationSettings } from '../invocation-params/runner-app-configuration-settings';
import {
  AppTranslator,
  DefaultAppTranslator,
} from './translations/static-translator';
import { TranslationSource } from './translations/translation-store';
import { translationInfoMap } from './translations/translation-map';

const RuntimeAppConfigurationStore = {
  initializedAppConfiguration: undefined as RunnerAppConfiguration | undefined,
};

export type RunnerAppConfiguration = {
  settings: RunnerAppConfigurationSettings;
  translator: AppTranslator;
};
export type TranslatorSourceFactory = () => TranslationSource;

type AppConfigurationFactoryParams = {
  params: RunnerAppConfigurationSettings;
  translatorSourceFactory?: TranslatorSourceFactory;
};
export function createAppConfiguration({
  params,
  translatorSourceFactory,
}: AppConfigurationFactoryParams): RunnerAppConfiguration {
  return {
    settings: params,
    translator: new DefaultAppTranslator(
      params.languages,
      translatorSourceFactory
        ? translatorSourceFactory()
        : (lang: string, key: string) => {
            if (translationInfoMap[lang]) {
              return translationInfoMap[lang].translations[key];
            }
          },
      undefined,
      (key) => {
        console.error(
          `Translation key not found: ${key} (neither lang nor defaults)`,
        );
        if (process.env.NODE_ENV === 'development') {
          throw new Error(
            `Translation key not found: ${key} (neither lang nor defaults)`,
          );
        }
        return `Unknown translation ${key}`;
      },
    ),
  };
}

export function installOrLookupRuntimeAppConfiguration(
  params: AppConfigurationFactoryParams,
) {
  if (RuntimeAppConfigurationStore.initializedAppConfiguration) {
    return RuntimeAppConfigurationStore.initializedAppConfiguration;
  }
  const appConfiguration = createAppConfiguration(params);
  RuntimeAppConfigurationStore.initializedAppConfiguration = appConfiguration;
  return appConfiguration;
}

export function getInstalledRuntimeAppConfiguration():
  | RunnerAppConfiguration
  | undefined {
  return RuntimeAppConfigurationStore.initializedAppConfiguration;
}
