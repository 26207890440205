import { Amp } from '../../../../../tests/AMP/types';
import { TestType } from '../../../../types';
import { TreeSequence } from '../../../graph/tree-sequence/tree-sequence';
import { SortedTree } from '../../../graph/sorted-tree/sorted-tree';
import { MapScreenTreeNode } from '../../../graph/nodes/map-screen-tree-node';
import { AmpOptionsData } from '../../../../../tests/AMP/loader/view-data/amp-options-data';
import { StimulusContent } from '../../../screens/basic-views/stimulus-content';
import { TestTreeWalker, TrialPreviewTree } from './tree-walkers';

export const ampTreeWalker: TestTreeWalker<Amp> = {
  testType: TestType.AMP,
  transformTreeSequence: (
    treeSequence: TreeSequence,
    sortedTree: SortedTree,
    testAccessor,
  ): TrialPreviewTree & {
    structureInfo: {
      primeCategories: { label: string }[];
      primeCategoryStimuliSize: {
        testPool: number;
        practicePool: number;
      };
      targetCategoryStimuliSize: {
        testPool: number;
        practicePool: number;
      };
    };
  } => {
    const blocksNodes = sortedTree.rootNode.children.find(
      (n) => n.name === 'blocks',
    )?.children;
    if (!blocksNodes) {
      throw new Error('No blocks found in tree');
    }

    const structureInfo = {
      primeCategories: testAccessor.modelData.primes.categories.map((m) => ({
        label: m.label,
      })),
      primeCategoryStimuliSize: {
        testPool:
          testAccessor.modelData.primes.categories[0].testStimuliPool.length,
        practicePool:
          testAccessor.modelData.primes.categories[0].practiceStimuliPool
            .length,
      },
      targetCategoryStimuliSize: {
        testPool:
          testAccessor.modelData.targets.categories[0].testStimuliPool.length,
        practicePool:
          testAccessor.modelData.targets.categories[0].practiceStimuliPool
            .length,
      },
    };

    const blocks = blocksNodes.map((blockNode, blockIndex) => {
      const blockInfo = testAccessor.modelData.blocks[blockIndex];

      const trialsCandidates = blockNode.children.find(
        (n) => n.name === 'trials',
      );

      if (!trialsCandidates) {
        throw new Error('No trials found in block');
      }
      const trials = trialsCandidates.children.map((trialNode) => {
        const primeNode = trialNode.children.find((n) => n.name === 'prime');
        const targetNode = trialNode.children.find((n) => n.name === 'target');
        if (!primeNode || !targetNode) {
          throw new Error('No prime or target found in trial');
        }
        const primeNodeStimulus =
          primeNode.treeNode instanceof MapScreenTreeNode
            ? primeNode.treeNode.screenData instanceof AmpOptionsData
              ? primeNode.treeNode.screenData.content instanceof StimulusContent
                ? primeNode.treeNode.screenData.content.stimulus
                : undefined
              : undefined
            : undefined;
        const targetNodeStimulus =
          targetNode.treeNode instanceof MapScreenTreeNode
            ? targetNode.treeNode.screenData instanceof AmpOptionsData
              ? targetNode.treeNode.screenData.content instanceof
                StimulusContent
                ? targetNode.treeNode.screenData.content.stimulus
                : undefined
              : undefined
            : undefined;
        if (!primeNodeStimulus || !targetNodeStimulus) {
          throw new Error('No stimulus found in prime or target');
        }

        const primeCategoriesModel = testAccessor.modelData.primes.categories;
        const targetCategoriesModel = testAccessor.modelData.targets.categories;
        const primeCategoriesStimuli = new Map(
          primeCategoriesModel
            .flatMap((pA) => [
              ...pA.practiceStimuliPool.map((s, index) => ({
                type: 'prime',
                category: pA.label,
                listIndex: index,
                pool: 'practice',
                stimulus: s,
              })),
              ...pA.testStimuliPool.map((s, index) => ({
                type: 'prime',
                category: pA.label,
                listIndex: index,
                pool: 'test',
                stimulus: s,
              })),
            ])
            .map((s) => [s.stimulus.id, s]),
        );
        const targetCategoriesStimuli = new Map(
          targetCategoriesModel
            .flatMap((pA) => [
              ...pA.practiceStimuliPool.map((s, index) => ({
                type: 'target',
                category: pA.label,
                listIndex: index,
                pool: 'practice',
                stimulus: s,
              })),
              ...pA.testStimuliPool.map((s, index) => ({
                type: 'target',
                category: pA.label,
                listIndex: index,
                pool: 'test',
                stimulus: s,
              })),
            ])
            .map((s) => [s.stimulus.id, s]),
        );

        const primeNodeInfos = primeCategoriesStimuli.get(
          primeNodeStimulus.data.identifier,
        )!;
        const targetNodeInfos = targetCategoriesStimuli.get(
          targetNodeStimulus.data.identifier,
        )!;

        // console.log('Target stimulus', targetNode.treeNode, targetNodeStimulus);
        return {
          trialName: trialNode.name,
          presentedStimuli: [
            {
              stimulusType: 'prime',
              stimulusInfo: {
                typeName: 'prime',
                id: primeNodeStimulus.data.identifier,
                textValue: primeNodeStimulus.data.textValue,
                primeCategory: primeNodeInfos.category,
                primePool: primeNodeInfos.pool,
                listIndex: primeNodeInfos.listIndex,
              },
              stimulusData: primeNodeStimulus,
            },
            {
              stimulusType: 'target',
              stimulusInfo: {
                typeName: 'target',
                id: targetNodeStimulus.data.identifier,
                textValue: targetNodeStimulus.data.textValue,
                targetPool: targetNodeInfos.pool,
                listIndex: targetNodeInfos.listIndex,
              },
              stimulusData: targetNodeStimulus,
            },
          ],
        };
      });
      return {
        name: `${blockInfo.name}(${blockNode.name})`,
        blockInfo: {
          blockType: blockInfo.type,
          amountOfTrials: blockInfo.amountTrials,
        },
        trials,
      };
    });

    return {
      structureInfo,
      blocks,
    };
  },
};
