import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useRunnerAppTranslator } from '../../state/baseTestContext/app-configuration/translations/translator.hook';
import { Translations } from '../../state/baseTestContext/app-configuration/translations/app-translations';
import { TestCancelButtonView } from '../TestCancelButton';

export function TestCanvasMemoryErrorView({
  onFinish,
}: {
  onFinish: () => void;
}) {
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Errors.TestInitError.TestCanvasMemoryError,
  );
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <TestCancelButtonView onFinish={onFinish} />

      {screenTranslator.messages
        .mapTranslation((msg) => [
          <Typography>
            {msg.flatMap((m) => [m, <br />] as React.ReactNode[]).slice(0, -1)}
          </Typography>,
          <br />,
          <br />,
        ])
        .flat()
        .slice(0, -2)}
      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        {screenTranslator.button.translate().join(' / ')}
      </Button>
    </Box>
  );
}
