import { TestType } from '../../../types';
import { getRunnerConfiguration } from '../../runner-config-map';
import { TestBaseContext } from '../../state/baseTestContext/context/ContextTypes';
import React from 'react';
import { DrawableDimension } from '../../media/drawable-dimensions';
import { S3TestLoadingManager } from '../../media/media-resource-loader';
import { Subject } from 'rxjs';
import { TestFlowSnapshot } from '../../graph-state/test-director';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { TestFinishEvent } from '../../controls/finish-event';
import { createResultPersister } from '../../result-screens';
import { TestLoadingState } from '../../loading-state';
import { CanvasMemoryError } from '../../canvas-memory-error';
import Log from '../../../../logger';
import { TestFinalizationQueries } from '../../../../tests/shared-queries';
import { LoadedTestExecution } from './LoadedTestExecution';
import { getGraphQL } from '../../../../utils';
import { StageSetupStage } from './StageSetupStage';
import { installBeforeLeaveTestPrompt } from './leave-test-prompt';
import { ParticipantStageSetupStateContent } from './ParticipantStageSetupStateContent';
import { TestTree } from '../../graph/test-tree';
import { TestResourceAccessor } from '../../media/test-resource-loader';
import { NewGQL } from '../../../../../GQL';
import { TestFinalization } from '../../../../../API';
import { RunnerConfiguration } from '../../runner-configuration';
import { TreeSequence } from '../../graph/tree-sequence/tree-sequence';

function prepareTestUI(
  testLoader: RunnerConfiguration<any>,
  {
    gql,
    ...res
  }: {
    tree: TestTree;
    sequence: TreeSequence;
    structure: { accessor: TestResourceAccessor<any> };
    gql: NewGQL;
    finalizationEntry: TestFinalization;
  },
) {
  const views = testLoader.createViews(res.structure);
  const selectorMap = testLoader.selectorTree.processNodes(
    Array.from(res.sequence.nodeMap.values()).map((v) => v.node),
  );
  const scaler = views.createScreenScaler(selectorMap);
  const testSize = new DrawableDimension(window.innerWidth, window.innerHeight);
  const resultQueue = createResultPersister(
    res.structure.accessor.testContext,
    res.structure.accessor.modelData.status,
    res.finalizationEntry.id,
    gql,
  );

  return scaler.scaleTestScreen(testSize).then((res1) => {
    gql.execute(
      TestFinalizationQueries.Update.create({
        input: {
          id: res.finalizationEntry.id,
          testStartedAt: new Date().toISOString(),
          status: res.structure.accessor.modelData.status,
        },
      }),
    );
    const flowSubject = new Subject<TestFlowSnapshot>();
    return {
      resultManager: resultQueue,
      subject: flowSubject,
      runnerConfig: testLoader,
      treeSequence: res.sequence,
      structure: res.structure,
      screenController: res1.createScreenController(flowSubject),
    };
  });
}

function processStageSetup(
  loadingStateStream: Subject<TestLoadingState>,
  testContext: TestBaseContext,
  container: HTMLElement | null,
  setSetupState: (setupState: StageSetupStage) => void,
  setLoadedTest: (loadedTest: LoadedTestExecution) => void,
) {
  const finishListener = () => {
    setSetupState('finish-results');
  };

  if (container) {
    const testLoader = getRunnerConfiguration(
      testContext.testIdentity.testType,
    );
    getGraphQL()
      .then(async (client) => {
        const finalizationEntry = await client.execute(
          TestFinalizationQueries.Create.create({
            input: {
              testId: testContext.testIdentity.testId,
              probandId: testContext.testIdentity.probandId,
              status: null,
              testType: testContext.testIdentity.testType,
              loadingStartedAt: new Date().toISOString(),
            },
          }),
        );
        return {
          ...(await testLoader.load(
            testContext,
            client,
            S3TestLoadingManager((state) => {
              loadingStateStream.next(state);
            }),
          )),
          gql: client,
          finalizationEntry,
        };
      })
      .then((res) => {
        container.addEventListener(TestFinishEvent.EventName, finishListener);
        prepareTestUI(testLoader, res)
          .then((loadedTest) => {
            setLoadedTest(loadedTest);
          })
          .catch((err) => {
            if (err instanceof CanvasMemoryError) {
              setSetupState('canvas-error');
              Log.error(
                `Canvas memory usage exceeded for test ${testContext.testIdentity.testId}. Device: ${window.navigator.userAgent}`,
              );
            }
          });
      })
      .catch((err) => {
        setSetupState('error');
        Log.error(
          `Error loading test ${testContext.testIdentity.testType} #${testContext.testIdentity.testId}`,
          err,
        );
      });
  }
}

export function ParticipantStageContainer({
  testContext,
  testType,
  onFinish,
}: {
  testContext: TestBaseContext;
  testType: TestType;
  onFinish: () => void;
}) {
  const handle = useFullScreenHandle();
  const [setupState, setSetupState] = React.useState<StageSetupStage>('init');
  const loadingStateStream = React.useMemo(
    () => new Subject<TestLoadingState>(),
    [],
  );
  const stageContainerRef = React.useRef<HTMLDivElement | null>(null);
  const [loadedTest, setLoadedTest] = React.useState<LoadedTestExecution>();
  const [fullscreen, setFullscreen] = React.useState(false);

  /* Prompt before leaving in case test is running*/
  React.useEffect(() => installBeforeLeaveTestPrompt(setupState), [setupState]);

  React.useEffect(
    () => {
      if (setupState === 'done') {
        processStageSetup(
          loadingStateStream,
          testContext,
          stageContainerRef.current,
          setSetupState,
          setLoadedTest,
        );
      }
      /* eslint-disable react-hooks/exhaustive-deps */
    },
    [
      testType,
      testContext,
      setupState,
      onFinish,
    ] /* CAUTION: DO ONLY UPDATE IF YOU KNOW HOW TO TEST IT - MAYBE NO STRAIGHT FORWARD REACT LOGIC */,
  );
  return (
    <FullScreen handle={handle}>
      <div
        style={{
          height: '100%',
          width: '100%',
          userSelect: 'none',
          backgroundColor: 'white',
        }}
        ref={stageContainerRef}
      >
        <ParticipantStageSetupStateContent
          testContext={testContext}
          stageContainerRef={stageContainerRef}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          handle={handle}
          loadedTest={loadedTest}
          loadingStateStream={loadingStateStream}
          onFinish={onFinish}
          setSetupState={setSetupState}
          setupState={setupState}
        />
      </div>
    </FullScreen>
  );
}
