import { StageSetupStage } from './StageSetupStage';
import { TestBaseContext } from '../../state/baseTestContext/context/ContextTypes';
import { FullScreenHandle } from 'react-full-screen';
import { LoadedTestExecution } from './LoadedTestExecution';
import { Subject } from 'rxjs';
import { TestLoadingState } from '../../loading-state';
import React, { MutableRefObject } from 'react';
import { ParticipantStageSetup } from './participant-stage-setup';
import { EnsureMobileFullscreen } from './EnsureMobileFullscreen';
import { TestLoadingErrorView } from './TestLoadingErrorView';
import { TestCanvasMemoryErrorView } from './TestCanvasMemoryErrorView';
import { TestLoadingSpinner } from '../../state-display-screens/loading-screens/test-loading-spinner';
import { WaitForResultsView } from '../../state-display-screens/result-screens/wait-for-results-view';
import { PostTestView } from '../../state-display-screens/result-screens/post-test-view';

export function ParticipantStageSetupStateContent({
  setupState,
  testContext,
  setSetupState,
  setFullscreen,
  handle,
  loadedTest,
  loadingStateStream,
  fullscreen,
  stageContainerRef,
  onFinish,
}: {
  setSetupState: (state: StageSetupStage) => void;
  setFullscreen: (v: boolean) => void;
  fullscreen: boolean;
  testContext: TestBaseContext;
  setupState: StageSetupStage;
  handle: FullScreenHandle;
  loadedTest: LoadedTestExecution | undefined;
  loadingStateStream: Subject<TestLoadingState>;
  stageContainerRef: MutableRefObject<HTMLDivElement | null>;
  onFinish: () => void;
}) {
  const shutdownTest = React.useCallback(() => {
    setSetupState('finished');
    if (handle.active) {
      handle.exit();
    }
    onFinish();
  }, [handle, handle.active]);
  return (
    <>
      {setupState === 'init' && (
        <ParticipantStageSetup
          testContext={testContext}
          handle={handle}
          onShutdown={shutdownTest}
          onFinish={(fullscreen) => {
            setSetupState('done');
            setFullscreen(fullscreen);
          }}
        />
      )}
      {setupState === 'done' && (
        <>
          {!loadedTest && (
            <TestLoadingSpinner
              testContext={testContext}
              stateStream={loadingStateStream}
              onShutdown={shutdownTest}
            />
          )}
          {loadedTest && (
            <EnsureMobileFullscreen
              fullscreen={fullscreen}
              onFinish={shutdownTest}
              handle={handle}
              testContext={testContext}
              loadedTest={loadedTest}
              stageContainerRef={stageContainerRef}
            />
          )}
        </>
      )}
      {setupState === 'finish-results' && (
        <WaitForResultsView
          resultManager={loadedTest!.resultManager}
          onFinish={shutdownTest}
        />
      )}
      {setupState === 'error' && (
        <TestLoadingErrorView onFinish={shutdownTest} />
      )}
      {setupState === 'canvas-error' && (
        <TestCanvasMemoryErrorView onFinish={shutdownTest} />
      )}
      {setupState === 'finished' && <PostTestView />}
    </>
  );
}
