import { SliceSnapshot, StateHolder } from './state-holder';
import { GQLQueryInstance } from '../../../../GQL';
import { Observer } from 'rxjs';
import { ControlOccurrence } from '../controls/control-stereotypes';
import { TransitionAction } from './graph-action';
import { ReactionTimeProcessor } from '../controls/reaction-time-processor';
import { TreeSequence } from '../graph/tree-sequence/tree-sequence';
import { TreeTransition } from '../graph/tree-sequence/tree-transition';
import { NodePathEntry } from '../graph/tree-sequence/node-path-entry';

export interface ResultQueue {
  enqueueResult(queryInstance: GQLQueryInstance<any, any>): void;
}

export type ProcessControlType =
  | {
      type: 'tree-transition';
      controlOccurrence: ControlOccurrence<any>;
    }
  | {
      type: 'intercepting-transition';
      interceptor: (
        treeSequence: TreeSequence,
        pathEntry: NodePathEntry,
      ) =>
        | {
            treeTransition: TreeTransition;
            stateModifications?: SliceSnapshot<any>[];
            resultQuery?: GQLQueryInstance<any, any>;
          }
        | undefined;
    };
export interface TestFlowSnapshot {
  screenNode: NodePathEntry;
  stateHolder: StateHolder;
  timeProcessor: ReactionTimeProcessor;
}

export class TestDirector {
  private stateHolder = new StateHolder();
  private currentNode: NodePathEntry;
  private currentTiming: ReactionTimeProcessor;

  constructor(
    private treeSequence: TreeSequence,
    private flowObserver: Observer<TestFlowSnapshot>,
    private resultQueue: ResultQueue,
  ) {
    this.currentNode = this.processTransition(
      treeSequence.start.get(treeSequence.tree.modifiers.default)!,
    );
    this.currentTiming = ReactionTimeProcessor.StartProcessor();
    this.informObservers();
  }
  getStateHolder() {
    return this.stateHolder;
  }
  public externStateChange(node: NodePathEntry) {
    this.currentNode = node;
    this.currentTiming = ReactionTimeProcessor.StartProcessor();
    this.informObservers();
  }

  processControl(request: ProcessControlType) {
    if (request.type === 'tree-transition') {
      const controlTransition =
        this.currentNode.screenData.controlTransitions.find(
          (ct) =>
            ct.controlRequest.stereotype ===
            request.controlOccurrence.stereotype,
        );
      if (controlTransition) {
        this.currentTiming.processTime(
          'controlStart',
          request.controlOccurrence.trigger.occurredOn,
        );
        this.processAction(
          controlTransition.actionFactory.instanceAction(
            {
              occurrence: request.controlOccurrence,
              reactionTimeProcessor: this.currentTiming,
            },
            this.stateHolder,
          ),
        );
      }
    } else {
      const transition = request.interceptor(
        this.treeSequence,
        this.currentNode,
      );
      if (transition?.resultQuery) {
        this.resultQueue.enqueueResult(transition.resultQuery);
      }
      this.executeTransition(
        transition?.treeTransition,
        ...(transition?.stateModifications ?? []),
      );
    }
  }

  protected processTransition(
    transition: TreeTransition,
    ...stateSnapshots: SliceSnapshot<[]>[]
  ) {
    this.moveTreeUp(transition);
    stateSnapshots.forEach((snap) => {
      this.stateHolder.upsert(snap);
    });
    this.moveTreeDown(transition);
    return this.treeSequence.nodeMap.get(transition.screen)!;
  }

  protected moveTreeDown(transition: TreeTransition) {
    transition.in.forEach((tn) => {
      if (tn.stateSlice) {
        this.stateHolder.init(tn.stateSlice);
      }
    });
    if (transition.screen.stateSlice) {
      this.stateHolder.init(transition.screen.stateSlice);
    }
  }

  protected moveTreeUp(transition: TreeTransition) {
    transition.out.forEach((tn) => {
      if (tn.stateSlice) {
        this.stateHolder.erase(tn.stateSlice);
      }
    });
  }

  protected informObservers() {
    this.flowObserver.next({
      screenNode: this.currentNode,
      stateHolder: this.stateHolder,
      timeProcessor: this.currentTiming,
    });
  }

  protected processAction(transitionAction: TransitionAction) {
    if (transitionAction.result) {
      this.resultQueue.enqueueResult(transitionAction.result);
    }
    const transition = this.currentNode.transitions.get(
      transitionAction.transitionModifier,
    );
    this.executeTransition(transition, ...transitionAction.stateModifications);
  }

  private executeTransition(
    transition: TreeTransition | undefined,
    ...stateModifications: SliceSnapshot<any>[]
  ) {
    if (!transition) {
      this.tearDownTest();
    } else {
      this.currentNode = this.processTransition(
        transition,
        ...stateModifications,
      );
      this.currentTiming = ReactionTimeProcessor.StartProcessor();
      this.informObservers();
    }
  }

  protected tearDownTest() {
    this.flowObserver.complete();
  }
}
