import {
  BlockType,
  CategoryPreviewDisplay,
  CategoryVariant,
  CreateIatTestInput,
  IatCategoriesInput,
  IatFeedbackType,
  LabeledStimuliPoolInput,
  MediaItemSnapshotScope,
  TestStatus,
  TextAlignment,
} from '../../../../API';
import {
  createDefaultFontStyle,
  createDefaultStyle,
} from '../../testAdmin/testEditor/initialEditorStates';
import { Iat } from '../types';
import iatTemplateStore from './templates/iat-templates';

const createEmptyCategory = (): LabeledStimuliPoolInput => ({
  label: {
    id: '',
    scope: MediaItemSnapshotScope.PRIVATE,
    tags: [],
  },
  practiceStimuliPool: [],
  testStimuliPool: [],
});

const iatCategoriesInput = (): IatCategoriesInput => ({
  fontStyle: createDefaultFontStyle(TextAlignment.CENTER),
  categoryA: createEmptyCategory(),
  categoryB: createEmptyCategory(),
});

const InitialIatCreatorState: CreateIatTestInput = {
  feedback: IatFeedbackType.BLOCKING,
  startLabel: 'Start',
  nextLabel: 'Next',
  finishLabel: 'Finish',
  categoryDivider: {
    fontStyle: createDefaultFontStyle(TextAlignment.CENTER),
    desktop: '',
  },
  name: '',
  instructions: {
    desktop: '',
  },
  description: '',
  blocks: [
    {
      index: 0,
      name: '',
      type: BlockType.NON_PRACTICE,
      amountTrials: 10,
      instructions: {
        desktop: '',
      },
      targetCategoryVariant: CategoryVariant.DEFAULT,
      categoryLabelsInInstructions: false,
    },
  ],
  keyboard: {
    left: '',
    right: '',
    confirm: '',
  },
  targetCategories: iatCategoriesInput(),
  attributeCategories: iatCategoriesInput(),
  probabilityWithoutReplacement: true,
  counterbalancing: true,
  style: createDefaultStyle(),
  interTrialInterval: 250,
  previewCategories: CategoryPreviewDisplay.INSTRUCTIONS,
  status: TestStatus.DRAFT,
};

export const iatTemplates: Iat[] = iatTemplateStore;
export default InitialIatCreatorState;
