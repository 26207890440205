import { FullScreenHandle } from 'react-full-screen';
import { TestBaseContext } from '../../state/baseTestContext/context/ContextTypes';
import { LoadedTestExecution } from './LoadedTestExecution';
import React, { MutableRefObject } from 'react';
import { ParticipantStage } from './ParticipantStage';

import { FullscreenPausedView } from '../../state-display-screens/loading-screens/fullscreen-paused-view';
import { TestCancelButtonView } from '../TestCancelButton';

export function EnsureMobileFullscreen({
  handle,
  testContext,
  loadedTest,
  stageContainerRef,
  fullscreen,
  onFinish,
}: {
  onFinish: () => void;
  fullscreen: boolean;
  handle: FullScreenHandle;
  testContext: TestBaseContext;
  loadedTest: LoadedTestExecution;
  stageContainerRef: MutableRefObject<HTMLDivElement | null>;
}) {
  const testComponent = React.useMemo(
    () => {
      return (
        <ParticipantStage
          loadedTest={loadedTest}
          containerRef={stageContainerRef}
        />
      );
      /* eslint-disable react-hooks/exhaustive-deps */
    },
    [] /* CAUTION: This is probably necessary to hold state - do not add deps without knowing how to verify it works */,
  );
  return (
    <>
      {testComponent}
      {fullscreen &&
        testContext.deviceInfo.mode === 'mobile' &&
        !handle.active && <FullscreenPausedView handle={handle} />}
      <TestCancelButtonView onFinish={onFinish} />
    </>
  );
}
