import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useRunnerAppTranslator } from '../../state/baseTestContext/app-configuration/translations/translator.hook';
import { translations } from '../../state/baseTestContext/app-configuration/translations/translation-builder';
import { Translations } from '../../state/baseTestContext/app-configuration/translations/app-translations';
import { TestCancelButtonView } from '../TestCancelButton';

export const TestLoadingErrorScreenTranslations = translations.scope(
  'Test Loading Error Screen',
  {
    message: translations.text('Error Message', {
      en: 'An error occurred while loading the test.',
    }),
    buttonLabel: translations.text('Reload Button Label', {
      en: 'Reload',
    }),
  },
);

export function TestLoadingErrorView({ onFinish }: { onFinish: () => void }) {
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Errors.TestLoadingErrorScreen,
  );
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <TestCancelButtonView onFinish={onFinish} />
      {screenTranslator.message.mapTranslation((msg) => (
        <Typography>{msg}</Typography>
      ))}
      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        {screenTranslator.buttonLabel.translate().join(' / ')}
      </Button>
    </Box>
  );
}
