import { ResultPersisterQueue } from '../../../../events/result-persister';
import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useRunnerAppTranslator } from '../../state/baseTestContext/app-configuration/translations/translator.hook';
import { translations } from '../../state/baseTestContext/app-configuration/translations/translation-builder';
import { Translations } from '../../state/baseTestContext/app-configuration/translations/app-translations';
import { TestCancelButtonView } from '../../stage/TestCancelButton';

export function WaitForResultsView({
  resultManager,
  onFinish,
}: {
  resultManager: ResultPersisterQueue;
  onFinish: () => void;
}) {
  const screenTranslator = useRunnerAppTranslator(
    Translations.App.Finish.WaitForResultsViewScreen,
  );
  const [progress, setProgress] = React.useState<{
    currentAmount: number;
    totalAmount: number;
  }>({ currentAmount: 0, totalAmount: 0 });
  React.useEffect(() => {
    resultManager
      .shutdownMonitor((c, t) => {
        setProgress({ currentAmount: c, totalAmount: t });
      })
      .then(() => {
        onFinish();
      });
  }, [resultManager, onFinish]);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      flexDirection="column"
    >
      <TestCancelButtonView onFinish={onFinish} />
      {screenTranslator.message.mapTranslation((txt) => (
        <Typography variant="body1" textAlign="center" key={txt}>
          {txt}
        </Typography>
      ))}
      <Box display="flex" width="25%" alignItems="center">
        <LinearProgress style={{ flexGrow: 1 }} />
        <Typography variant="caption">
          {progress.totalAmount - progress.currentAmount} /{' '}
          {progress.totalAmount}
        </Typography>
      </Box>
    </Box>
  );
}

export const WaitForResultsViewTranslations = translations.scope(
  'Wait for Results to be saved',
  {
    message: translations.text('Please wait message', {
      de: 'Bitte Geduld. Die Ergebnisse werden hochgeladen. Dies kann einen Moment dauern.',
      en: 'Please be patient. The results are being uploaded. This may take a moment.',
    }),
  },
);
