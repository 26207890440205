import { IatStructure } from '../structure/iat-structure';
import { IATModifiers } from './iat-modifiers';
import { IatMediaPool } from '../loading/load-iat-media';
import { DefaultTransitionAction } from '../../../../subject/testRunner/graph-state/graph-action';
import { IatFeedbackType } from '../../../../../API';
import {
  ControlStereotype,
  OptionsStereotypes,
} from '../../../../subject/testRunner/controls/control-stereotypes';
import { IATStimulusSlice } from './iat-tree-state';
import { IatOptionsData } from '../view-data/iat-options-data';
import { StimulusContent } from '../../../../subject/testRunner/screens/basic-views/stimulus-content';
import { buildCreateResultQuery } from './iat-tree-result-query-builder';
import { IatBlockPhase } from '../structure/iat-block-structure';
import {
  BlockPhaseDescriptor,
  TrialPhaseDescriptor,
} from '../../../../subject/testRunner/utils/tree-utils';
import { MapScreenTreeNode } from '../../../../subject/testRunner/graph/nodes/map-screen-tree-node';
import { MapPhaseTreeNode } from '../../../../subject/testRunner/graph/nodes/map-phase-tree-node';

export type IatTrialPhaseDescriptor = TrialPhaseDescriptor<
  IatBlockPhase,
  IatMediaPool['test']['presentations']['values'][number]
>;

export function createBlockTrialsPhaseTree(
  iatStructure: IatStructure,
  blockDescriptor: BlockPhaseDescriptor<IatBlockPhase>,
) {
  const { block, blockNode } = blockDescriptor;
  const trialsNode = new MapPhaseTreeNode('trials', blockNode)
    .connectParent()
    .withDisplayName(`Trials`);

  const trialNodes = block.trialStimuli.map((trial, trialIndex) =>
    createSingleTrialPhaseTree(iatStructure, {
      ...blockDescriptor,
      trialNode: new MapPhaseTreeNode('trial-' + trialIndex, trialsNode)
        .connectParent(trialIndex === 0 ? IATModifiers.default : undefined)
        .withDisplayName(`Trial ${trialIndex + 1}`),
      trialIndex,
      trial,
    }),
  );

  trialNodes.reduce((acc, c) => c.transitionFrom(IATModifiers.default, acc));

  return trialsNode;
}

function createSingleTrialPhaseTree(
  iatStructure: IatStructure,
  trialDescriptor: IatTrialPhaseDescriptor,
) {
  const { trialNode, trial, block } = trialDescriptor;

  const createResult = buildCreateResultQuery(iatStructure, trialDescriptor);

  const defineControlRequest = (
    controlStereotype: ControlStereotype<any, any>,
    side: 'left' | 'right',
  ) =>
    controlStereotype.defineTransition(
      undefined,
      trial.metaData.variant ===
        block.blockDescriptor.options.getOptionVariant(
          trial.metaData.category,
          side,
        )
        ? (control) =>
            DefaultTransitionAction.result(
              IATModifiers.default,
              createResult(control, side, 'correct'),
            )
        : (control) =>
            DefaultTransitionAction.result(
              IATModifiers.fail,
              createResult(control, side, 'incorrect'),
              IATStimulusSlice.createSnapshot({
                fail:
                  iatStructure.accessor.modelData.feedback ===
                    IatFeedbackType.FEEDBACK_BLOCKING ||
                  iatStructure.accessor.modelData.feedback ===
                    IatFeedbackType.FEEDBACK_NON_BLOCKING,
              }),
            ),
      iatStructure.accessor.modelData.feedback ===
        IatFeedbackType.FEEDBACK_NON_BLOCKING
        ? (stateHolder) => {
            const snapshot = stateHolder.get(IATStimulusSlice);
            return !snapshot.data.fail;
          }
        : undefined,
    );

  const stimulusNode = new MapScreenTreeNode(
    'stimulus',
    trialNode,
    new IatOptionsData(
      block.instructions.tips
        ? {
            content: block.instructions.tips.text,
            textStyle: block.instructions.tips.style,
          }
        : null,
      block.blockDescriptor.options,
      new StimulusContent(trial.value),
    ),
    IATStimulusSlice,
  )
    .connectParent(IATModifiers.default)
    .withDisplayName(`Trial ${trialDescriptor.trialIndex + 1} Stimulus`)
    .controlRequest(defineControlRequest(OptionsStereotypes.Side.Left, 'left'))
    .controlRequest(
      defineControlRequest(OptionsStereotypes.Side.Right, 'right'),
    );
  if (
    iatStructure.accessor.modelData.feedback ===
    IatFeedbackType.FEEDBACK_NON_BLOCKING
  ) {
    stimulusNode.controlRequest(
      OptionsStereotypes.TimeoutEvent.defineTransition(
        iatStructure.accessor.modelData.feedbackInterval ??
          iatStructure.accessor.modelData.interTrialInterval,
        () => DefaultTransitionAction.next(IATModifiers.default),
        (stateHolder) => {
          const snapshot = stateHolder.get(IATStimulusSlice);
          return snapshot.data.fail;
        },
      ),
    );
  }
  const blankNode = new MapScreenTreeNode(
    'blank',
    trialNode,
    new IatOptionsData(
      block.instructions.tips
        ? {
            content: block.instructions.tips.text,
            textStyle: block.instructions.tips.style,
          }
        : null,
      block.blockDescriptor.options,
      null,
    ),
  )
    .connectParent()
    .withDisplayName(`Trial ${trialDescriptor.trialIndex + 1} Blank`)
    .controlRequest(
      OptionsStereotypes.TimeoutEvent.defineTransition(
        iatStructure.accessor.modelData.interTrialInterval,
        () => DefaultTransitionAction.next(IATModifiers.default),
      ),
    )
    .transitionFrom(IATModifiers.default, stimulusNode);

  stimulusNode.transition(
    IATModifiers.fail,
    iatStructure.accessor.modelData.feedback !== IatFeedbackType.NON_BLOCKING
      ? stimulusNode
      : blankNode,
  );

  return trialNode;
}
