import { translations } from './translation-builder';
import { TestLoadingErrorScreenTranslations } from '../../../../stage/proband/TestLoadingErrorView';
import { TestLoadingScreenTranslations } from '../../../../state-display-screens/loading-screens/test-loading-spinner';
import { TestInitErrorTranslations } from '../../../../state-display-screens/error-screens/error-translations';
import { FullscreenPausedTranslations } from '../../../../state-display-screens/loading-screens/fullscreen-paused-view';
import { SwitchToFullscreenTranslations } from '../../../../state-display-screens/loading-screens/mobile-fullscreen-setup';
import { OrientationTranslations } from '../../../../state-display-screens/loading-screens/mobile-orientation-setup';
import { WaitForResultsViewTranslations } from '../../../../state-display-screens/result-screens/wait-for-results-view';
import { PostTestViewTranslations } from '../../../../state-display-screens/result-screens/post-test-view';
import { StaticTranslationKey } from './translation-key';
import { TranslationScopeMap } from './translation-scope';
import { omit } from 'lodash';
import { CancelTestButtonTranslations } from '../../../../stage/TestCancelButton';

const buildAppTranslations = () => {
  return translations
    .scope('MIND.set Runner Translations', {
      Errors: translations.scope('Errors', {
        TestLoadingErrorScreen: TestLoadingErrorScreenTranslations,
        TestInitError: TestInitErrorTranslations,
      }),
      Loading: translations.scope('Loading', {
        TestLoadingScreen: TestLoadingScreenTranslations,
        FullscreenPausedScreen: FullscreenPausedTranslations,
        SwitchToFullscreenScreen: SwitchToFullscreenTranslations,
        MobileOrientationScreen: OrientationTranslations,
      }),
      Finish: translations.scope('Finish', {
        WaitForResultsViewScreen: WaitForResultsViewTranslations,
        PostTestScreen: PostTestViewTranslations,
      }),
      Control: translations.scope('Control', { CancelTestButtonTranslations }),
    })
    .toScope('', 'App');
};

export const Translations = {
  get App() {
    function recurseTranslations(
      translations: TranslationScopeMap<any>,
    ): string[] {
      return [
        ...Object.values(omit(translations, 'scope')).flatMap((v) =>
          v instanceof StaticTranslationKey ? [v.key] : recurseTranslations(v),
        ),
      ];
    }

    return buildAppTranslations();
  },
};
