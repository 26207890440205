import { ControlStereotype } from './control-stereotypes';
import { ProcessControlType } from '../graph-state/test-director';
import { TestFinalizationQueries } from '../../../tests/shared-queries';
import { TimeMeasuredValue } from '../../../events/TimedEvent';

export class InterceptingControlStereotype<T> extends ControlStereotype<
  T,
  any
> {
  protected constructor(
    public name: string,
    readonly interceptorFactory: (
      triggerProp: TimeMeasuredValue<T>,
    ) => Extract<
      ProcessControlType,
      { type: 'intercepting-transition' }
    >['interceptor'],
  ) {
    super(name);
  }

  static EXIT_EXECUTION_CONTROL = new InterceptingControlStereotype<
    any & {
      finalizationId: string;
    }
  >('exit-execution', (trigger) => {
    return (treeSequence, pathEntry) => {
      return {
        treeTransition: {
          screen: Array.from(treeSequence.nodeMap.values()).find(
            (n) => n.transitions.size === 0,
          )!.node,
          in: [],
          out: [],
        },
        resultQuery: TestFinalizationQueries.Update.create({
          input: {
            id: trigger.value.finalizationId,
            testAbortedAt: new Date().toISOString(),
          },
        }),
      };
    };
  });
}
